// import moment from "moment-timezone";

// export function convertUtcToCt(utcDate) {
//   console.log("in", utcDate);
//   const ctDate = moment(utcDate).tz("America/Chicago").format("MM/DD HH:mm"); // 'America/Chicago' is the timezone for Central Time
//   console.log("out", ctDate);
//   return ctDate;
// }

export function convertUtcToCt(utcDate) {
  // Get the current date in the local time zone
  const currentDate = new Date();
  // console.log("utcDate", utcDate);

  // Determine if the current date is within the daylight saving time (CDT) period
  const isDaylightSavingTime = () => {
    // NEEDS TO BE ADJUSTED TO BE EXACT, OR MANUALLY CHANGE
    const currentYear = currentDate.getFullYear();
    // Daylight Saving Time in the United States starts on the second Sunday in March (month 2)
    const startDstDate = new Date(currentYear, 2, 8);
    // Daylight Saving Time in the United States ends on the first Sunday in November (month 10)
    const endDstDate = new Date(currentYear, 10, 1);

    return currentDate >= startDstDate && currentDate < endDstDate;
  };

  // Get the offset in minutes for CST or CDT based on the current time
  const cstOffset = isDaylightSavingTime() ? -5 * 60 : -6 * 60;
  // Calculate the CT time by adding the offset to the UTC time
  const ctTime = new Date(utcDate.getTime() + cstOffset * 60 * 1000);

  // Define the time zone abbreviation based on whether it's CST or CDT
  const timeZoneAbbreviation = isDaylightSavingTime() ? "CDT" : "CST";

  // Format the CT time as a string including the time zone abbreviation
  const ctTimeString = ctTime.toLocaleString("en-US", {
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  const timeOnly = ctTime.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  const fullDateOnly = ctTime.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });

  return {
    dateTime: ctTime,
    dateString: fullDateOnly,
    timeString: `${timeOnly}`,
    fullTime: `${ctTimeString} ${timeZoneAbbreviation}`,
  };
}
