import { useState, useEffect } from "react";
import { convertUtcToCt } from "./helpers";
import "./App.css";
import axios from "axios";
// import jsonData from "./ads.json";

const baseUrl = "https://scorestream.com/api";
const apiKey = process.env.REACT_APP_API_KEY;

const getRandomIndex = (arrayLength) => {
  let x = Math.floor(Math.random() * arrayLength);
  return x;
};

const getImageUrl = (imageName) => {
  // Assuming your images are stored in a "images" directory on the server
  return `/marketing/${imageName}`;
};

const widgets = {
  "All": 56288,
  "Cherokee": 56287,
  "DeKalb": 56198,
  "Etowah": 56286,
  "Jackson": 56284,
  "Marshall": 56285,
};

function App() {
  const [scoreData, setScoreData] = useState({});
  const [activeButton, setActiveButton] = useState("All");
  const [loading, setLoading] = useState(false);
  const [currentBannerIndex, setCurrentBannerIndex] = useState(0);
  // const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [jsonData, setJsonData] = useState({ banners: [], cards: [] });

  useEffect(() => {
    // Fetch the JSON data from the public folder
    // setLoading(true);
    axios
      .get("/marketing/ads.json")
      .then((response) => {
        console.log(response.data);
        setJsonData((prevData) => ({ ...prevData, ...response.data }));
        // console.log("fetched json data", response.data);
      })
      .catch((error) => {
        console.error("Error fetching JSON:", error);
      });
  }, []);

  // get data - previous way
  // const getData = async (widgetId) => {
  //   // setLoading(true);
  //   const payload = {
  //     "method": "recommended.broadcast.games.search",
  //     "params": {
  //       "userWidgetIds": [widgetId],
  //       "apiKey": apiKey,
  //     }, // 56198
  //   };
  //   const score = await axios.post(`${baseUrl}`, payload);
  //   // console.log(score.data.result);
  //   setScoreData(score.data.result);
  //   // setLoading(false);
  // };

  const getData = async (county) => {
    const lowerCaseCounty = county.toLowerCase();
    console.log(lowerCaseCounty);
    axios
      .get(`/api/gamescores_${lowerCaseCounty}.json`)
      .then((response) => {
        setScoreData(response.data.result);
      })
      .catch((error) => {
        console.error("Error fetching JSON:", error);
      });
  };

  useEffect(() => {
    // getData(widgets["All"]);
    getData("All");
    const newBannerIndex = getRandomIndex(jsonData.banners.length);
    setCurrentBannerIndex(newBannerIndex);
  }, [jsonData]);

  useEffect(() => {
    if (jsonData.banners.length > 0 && jsonData.cards.length > 0) {
      const interval = setInterval(() => {
        // getData(widgets[activeButton]);
        getData(activeButton);
      }, 120000); // Update every 2 min

      return () => clearInterval(interval);
    }
  }, [activeButton, jsonData]);

  // get the sport and game info
  const getGameCardDisplayInfo = (squadId) => {
    if (
      scoreData &&
      scoreData.collections &&
      scoreData.collections.squadCollection
    ) {
      return scoreData.collections.squadCollection.list
        .find((squad) => squad.id === squadId)
        .gameCardDisplay.slice(0, -7);
    }
    return null;
  };

  // Function to find team by ID in teamCollections
  const findTeamById = (teamId) => {
    if (
      scoreData &&
      scoreData.collections &&
      scoreData.collections.teamCollection
    ) {
      let currTeam = scoreData.collections.teamCollection.list.find(
        (team) => team.teamId === teamId
      );
      return currTeam;
    }
    return null;
  };

  const findTeamColors = (colorId1, colorId2) => {
    if (
      scoreData &&
      scoreData.collections &&
      scoreData.collections.colorCollection
    ) {
      let color1 = scoreData.collections.colorCollection.list.find(
        (color) => color.colorId === colorId1
      );

      let color2 = scoreData.collections.colorCollection.list.find(
        (color) => color.colorId === colorId2
      );
      return {
        one: `rgb(${color1.r},${color1.g}, ${color1.b})`,
        two: `rgb(${color2.r},${color2.g}, ${color2.b})`,
      };
    }
    return null;
  };

  // get mascot picture
  const getMascotPicture = (mascotId) => {
    if (
      scoreData &&
      scoreData.collections &&
      scoreData.collections.teamPictureCollection
    ) {
      let mascotPic = scoreData.collections.teamPictureCollection.list.find(
        (team) => team.teamPictureId === mascotId
      );

      return (
        <img
          className="mascot-pic"
          style={{ width: "36px" }}
          src={mascotPic.thumbnailUrl}
          alt="mascot"
        />
      );
    }
    return null;
  };

  // if no mascot picture, get team varsity letter for picture
  const displayLetter = (letter, color, borderColor) => {
    console.log(color, borderColor);
    return (
      <div
        style={{
          fontSize: "36px",
          fontWeight: "bold",
          fontFamily: "Old School United",
          color: `${color}`,
          textShadow: `-2px -2px 0 ${borderColor}, 2px -2px 0 ${borderColor}, -2px 2px 0 ${borderColor}, 2px 2px 0 ${borderColor}`,
        }}
      >
        {letter}
      </div>
    );
  };

  // get segment
  const getSegment = (segmentId) => {
    if (
      scoreData &&
      scoreData.collections &&
      scoreData.collections.gameSegmentCollection
    ) {
      const segment = scoreData.collections.gameSegmentCollection.list.find(
        (segment) => segment.gameSegmentId === segmentId
      );

      return segment.shortDisplay;
    }
    return null;
  };

  // set up a game card to display
  const renderGameCard = (game) => {
    if (
      scoreData &&
      scoreData.collections &&
      scoreData.collections.teamCollection
    ) {
      let homeTeam = findTeamById(game.homeTeamId),
        awayTeam = findTeamById(game.awayTeamId),
        homeTeamColors =
          homeTeam.color1Id && homeTeam.color2Id
            ? findTeamColors(homeTeam.color1Id, homeTeam.color2Id)
            : null,
        awayTeamColors =
          awayTeam.color1Id && awayTeam.color2Id
            ? findTeamColors(awayTeam.color1Id, awayTeam.color2Id)
            : null;

      let homeTeamMascotImage =
          homeTeam.mascotTeamPictureIds.length > 0
            ? getMascotPicture(homeTeam.mascotTeamPictureIds[0])
            : displayLetter(
                homeTeam.varsityLetter,
                homeTeamColors.one,
                homeTeamColors.two
              ),
        awayTeamMascotImage =
          awayTeam.mascotTeamPictureIds.length > 0
            ? getMascotPicture(awayTeam.mascotTeamPictureIds[0])
            : displayLetter(
                awayTeam.varsityLetter,
                awayTeamColors.one,
                awayTeamColors.two
              );

      const gameStartUtc = convertUtcToCt(
        new Date(game.startDateTime)
      ).dateTime;
      const nowLocal = new Date(); // This will be in the local time zone
      const gameStartLocal = new Date(gameStartUtc.getTime()); // Convert to local time
      const upcoming = gameStartLocal > nowLocal;
      // console.log(game.startDateTime);
      // console.log(gameStartLocal, nowLocal);

      return (
        <div className="card" key={game.gameId}>
          <div className="row card-header">
            <p className="game-card-title">
              {(
                getGameCardDisplayInfo(game.squadId) + game.sportName
              ).toUpperCase()}
            </p>
            <p>
              {convertUtcToCt(
                new Date(game.startDateTime)
              ).dateString.toUpperCase()}
            </p>
          </div>
          <div className="row">
            <div className="col-mascot">{homeTeamMascotImage}</div>
            <div className="col-team-info">
              <div className="row">
                <div className="name">{homeTeam.minTeamName}</div>
                <div className="mascot">{homeTeam.mascot1}</div>
              </div>
              <div className="row">
                <div className="location">
                  {homeTeam.city}, {homeTeam.state}
                </div>
              </div>
            </div>
            {upcoming ? (
              ""
            ) : (
              <div className="col-score">{game.lastScore.homeTeamScore}</div>
            )}
          </div>
          <div className="row-segment">
            <div className={` ${upcoming ? "upcoming" : "col-segment"}`}>
              {upcoming
                ? convertUtcToCt(new Date(game.startDateTime)).timeString
                : getSegment(game.lastScore.gameSegmentId)}
            </div>
          </div>
          <div className="row">
            <div className="col-mascot">{awayTeamMascotImage}</div>
            <div className="col-team-info">
              <div className="row">
                <div className="name">{awayTeam.minTeamName}</div>
                <div className="mascot">{awayTeam.mascot1}</div>
              </div>
              <div className="row">
                <div className="location">
                  {awayTeam.city}, {awayTeam.state}
                </div>
              </div>
            </div>
            {upcoming ? (
              ""
            ) : (
              <div className="col-score">{game.lastScore.awayTeamScore}</div>
            )}
          </div>
        </div>
      );
    }

    return null;
  };

  // Create a function to render ad card
  const renderAdCard = (adIndex) => {
    const adCardIndex = getRandomIndex(jsonData.cards.length);
    // console.log("adCardIndex", adCardIndex);
    // console.log(jsonData);
    return (
      <div key={`comm-${adIndex}`} className="comm-card">
        <div className="comm-image">
          <a
            href={jsonData.cards[adCardIndex]?.ref}
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={getImageUrl(jsonData.cards[adCardIndex]?.id)}
              alt="card_ad"
            />
          </a>
        </div>
      </div>
    );
  };

  // Map through the gameCollection list and insert ad cards
  const renderedCards =
    scoreData && scoreData.collections && scoreData.collections.gameCollection
      ? scoreData.collections.gameCollection.list.flatMap((game, index) => {
          const adInterval = jsonData.adIntervals; // Number of game cards before inserting an ad
          if (index === 0 || index % adInterval === 0) {
            return [renderGameCard(game), renderAdCard(index)];
          } else {
            return renderGameCard(game);
          }
        })
      : [];

  return (
    <div className="App">
      <div className="comm-banner">
        <div className="comm-banner-image">
          <a
            href={jsonData.banners[currentBannerIndex]?.ref}
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={getImageUrl(jsonData.banners[currentBannerIndex]?.id)}
              alt="ad banner"
            />
          </a>
        </div>
      </div>
      <div className="county-tabs">
        <button
          className={activeButton === "All" ? "active" : ""}
          onClick={() => {
            setActiveButton("All");
            getData("all"); // 56288
          }}
        >
          All
        </button>
        <button
          className={activeButton === "Cherokee" ? "active" : ""}
          onClick={() => {
            setActiveButton("Cherokee");
            getData("cherokee"); // 56287
          }}
        >
          Cherokee
        </button>
        <button
          className={activeButton === "DeKalb" ? "active" : ""}
          onClick={() => {
            setActiveButton("DeKalb");
            getData("dekalb"); // 56198
          }}
        >
          DeKalb
        </button>
        <button
          className={activeButton === "Etowah" ? "active" : ""}
          onClick={() => {
            setActiveButton("Etowah");
            getData("etowah"); // 56286
          }}
        >
          Etowah
        </button>
        <button
          className={activeButton === "Jackson" ? "active" : ""}
          onClick={() => {
            setActiveButton("Jackson");
            getData("jackson");
          }}
        >
          Jackson
        </button>
        <button
          className={activeButton === "Marshall" ? "active" : ""}
          onClick={() => {
            setActiveButton("Marshall");
            getData("marshall");
          }}
        >
          Marshall
        </button>
      </div>
      <div className="cards-container">{renderedCards}</div>

      <div className="footer">
        Visit our corporate page at&nbsp;
        <a
          href="https://southerntorch.com/"
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "none" }}
        >
          Southern Torch
        </a>
      </div>
    </div>
  );
}

export default App;
